<template>
    <div class="logo-wrap">
        <img class="logo" src="@/assets/images/logo@2x.webp" />
        <div class="name">{{ appTitle }}</div>
        <!--        <div class="line"></div>
        <div class="name-sub">智慧ERP</div>-->
    </div>
</template>

<script>
import { title } from '@/settings.js';
export default {
    computed: {
        appTitle() {
            return title;
        }
    }
};
</script>

<style lang="scss" scoped>
.logo-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    .logo {
        height: 29px;
        //width: 25px;
    }
    .name {
        margin-left: 5px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
    }
    .line {
        display: inline-block;
        height: 22px;
        width: 1px;
        background: rgba(255, 255, 255, 0.5);
        margin: 0 8px;
    }
    .name-sub {
        font-size: 16px;
        font-weight: 200;
        color: #ffffff;
        margin-left: 5px;
    }
}
</style>
