/*
 * @Author: web_liuqunyan
 * @Date: 2022-12-26 14:12:17
 * @LastEditors: 陈迪秀 1170776994@qq.com
 * @LastEditTime: 2023-08-28 11:39:17
 * @FilePath: /gggj_main_dev/src/store/modules/app.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { elementVariables } from '@hb/gggj_lib';
const defaultSettings = require('../../settings.js');

const state = {
    lang: defaultSettings.lang,
    theme: elementVariables.theme,
    visitedViews: [],
    //分页全局
    addPageSizes: [20, 50, 100, 200]
};

const mutations = {
    SET_LANG: (state, lang) => {
        state.lang = lang;
    },
    SET_THEME: (state, theme) => {
        state.theme = theme;
    },
    ADD_VISITEDVIEWS: (state, tag) => {
        if (
            state.visitedViews.find((item) => {
                item.path === tag.path;
            })
        ) {
            return;
        }
        if (!tag.meta.title) return false;
        // console.log('添加一个tag', tag, state.visitedViews);
        state.visitedViews.push(tag);
    },
    DELETE_VISITEDVIEWS: (state, index) => {
        state.visitedViews.splice(index, 1);
    },
    CLEAN_VISITED_VIEWS: (state) => {
        state.visitedViews.splice(0, state.visitedViews.length);
    },
    RESET_VISITEDVIEWS: (state) => {
        state.visitedViews = [];
    }
};

const actions = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
