/*
 * @Author: 陈迪秀 1170776994@qq.com
 * @Date: 2023-07-27 08:46:38
 * @LastEditors: 陈迪秀 1170776994@qq.com
 * @LastEditTime: 2023-08-28 11:34:41
 * @FilePath: \gggj_main\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import createPersistedState from 'vuex-persistedstate';
import createLogger from 'vuex/dist/logger';

Vue.use(Vuex);

const modulesFiles = require.context('./modules', true, /\.js$/);

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
    const value = modulesFiles(modulePath);
    modules[moduleName] = value.default;
    return modules;
}, {});

const store = new Vuex.Store({
    modules,
    getters,
    strict: true,
    plugins: [
        createPersistedState({
            // 需要本地持久储存的数据
            paths: [
                'user.loginInfo' // 记住用户登录账号密码
            ]
        }),
        createPersistedState({
            storage: window.sessionStorage,
            // 需要本地持久储存的数据
            paths: ['user.token', 'user.userInfo', 'app.addPageSizes', 'power.menuList', 'power.buttonPermission']
        }),
        createLogger()
    ]
});

export default store;
