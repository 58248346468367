<template>
    <svg :class="['svg-icon', className]" aria-hidden="true" v-on="$listeners">
        <use :xlink:href="iconName" />
    </svg>
</template>

<script>
export default {
    name: 'SvgIcon',
    props: {
        iconClass: {
            type: String,
            required: true
        },
        className: {
            type: String,
            default: ''
        }
    },
    computed: {
        iconName() {
            return `#icon-${this.iconClass}`;
        }
    }
};
</script>

<style scoped lang="scss">
.svg-icon {
    width: 16px;
    height: 16px;
}
</style>
