import { registerMicroApps, start } from 'qiankun';
import { getSysConfigValue } from './api/cus.js';
import store from './store';
const defaultSettings = require('./settings.js');

const myNextwork = defaultSettings.myNextwork;

function qiankunStart() {
    if (window.location.port === '1101') {
        registerMicroApps(
            // eslint-disable-next-line no-use-before-define
            setAppsConfig({
                gggjUiEntry: `${myNextwork}:1102/`,
                pmsEntry: `${myNextwork}:1103/`,
                scmEntry: `${myNextwork}:1104/`,
                contractEntry: `${myNextwork}:1105/`,
                tssEntry: `${myNextwork}:1106/`,
                crmEntry: `${myNextwork}:1107/`,
                wfsEntry: `${myNextwork}:1108/`,
                fmsEntry: `${myNextwork}:1109/`,
                emsEntry: `${myNextwork}:1110/`,
                sysEntry: `${myNextwork}:1111/`,
                plmEntry: `${myNextwork}:1112/`,
                fileEntry: `${myNextwork}:1113/`,
                hrsEntry: `${myNextwork}:1115/`,
                dcsEntry: `${myNextwork}:1114/`,
                bmsEntry: `${myNextwork}:1116/`,
                fasEntry: `${myNextwork}:1117/`,
                wfeEntry: `${myNextwork}:1118/`,
                saasEntry: `${myNextwork}:6001/`
            })
        );
        // 启动 qiankun
        start({ prefetch: false });
    } else {
        getSysConfigValue({
            params: {
                sysConfigKey: 'SYS_WEB_COMPONENT_URL'
            }
        }).then((res) => {
            registerMicroApps(
                // eslint-disable-next-line no-use-before-define
                setAppsConfig({
                    gggjUiEntry: JSON.parse(res.data)['gggj-ui'],
                    pmsEntry: JSON.parse(res.data)['production-management'],
                    scmEntry: JSON.parse(res.data)['scm'],
                    contractEntry: JSON.parse(res.data)['contract'],
                    tssEntry: JSON.parse(res.data)['tss'],
                    crmEntry: JSON.parse(res.data)['crm'],
                    wfsEntry: JSON.parse(res.data)['wfs'],
                    fmsEntry: JSON.parse(res.data)['fms'],
                    emsEntry: JSON.parse(res.data)['ems'],
                    sysEntry: JSON.parse(res.data)['sys'],
                    plmEntry: JSON.parse(res.data)['plm'],
                    fileEntry: JSON.parse(res.data)['file'],
                    hrsEntry: JSON.parse(res.data)['hrs'],
                    dcsEntry: JSON.parse(res.data)['dcs'],
                    bmsEntry: JSON.parse(res.data)['bms'],
                    fasEntry: JSON.parse(res.data)['fas'],
                    wfeEntry: JSON.parse(res.data)['wfe'],
                    saasEntry: JSON.parse(res.data)['saas']
                })
            );
            // 启动 qiankun
            start({
                prefetch: false
            });
        });
    }
}
// no-unused-vars
function setAppsConfig({
    gggjUiEntry = '',
    pmsEntry = '',
    scmEntry = '',
    contractEntry = '',
    tssEntry = '',
    crmEntry = '',
    wfsEntry = '',
    fmsEntry = '',
    emsEntry = '',
    sysEntry = '',
    plmEntry = '',
    fileEntry = '',
    hrsEntry = '',
    dcsEntry = '',
    bmsEntry = '',
    fasEntry = '',
    wfeEntry = '',
    saasEntry = ''
}) {
    return [
        {
            name: 'gggj-ui',
            entry: gggjUiEntry,
            container: '#sub-page-main',
            activeRule: (location) => {
                return location.href.includes(defaultSettings.routeBaseGggjUi);
            },
            props: {
                store,
                defaultSettings
            }
        },
        {
            name: 'production-management',
            entry: pmsEntry,
            container: '#sub-page-main',
            activeRule: (location) => {
                return location.href.includes(defaultSettings.routeBasePMS);
            },
            props: {
                store,
                defaultSettings
            }
        },
        {
            name: 'scm',
            entry: scmEntry,
            container: '#sub-page-main',
            activeRule: (location) => {
                return location.href.includes(defaultSettings.routeBaseScm);
            },
            props: {
                store,
                defaultSettings
            }
        },
        {
            name: 'contract',
            entry: contractEntry,
            container: '#sub-page-main',
            activeRule: (location) => {
                return location.href.includes(defaultSettings.routeBaseContract);
            },
            props: {
                store,
                defaultSettings
            }
        },
        {
            name: 'tss',
            entry: tssEntry,
            container: '#sub-page-main',
            activeRule: (location) => {
                return location.href.includes(defaultSettings.routeBaseTss);
            },
            props: {
                store,
                defaultSettings
            }
        },
        {
            name: 'crm',
            entry: crmEntry,
            container: '#sub-page-main',
            activeRule: (location) => {
                return location.href.includes(defaultSettings.routeBaseCrm);
            },
            props: {
                store,
                defaultSettings
            }
        },
        {
            name: 'wfs',
            entry: wfsEntry,
            container: '#sub-page-main',
            activeRule: (location) => {
                return location.href.includes(defaultSettings.routeBaseWfs);
            },
            props: {
                store,
                defaultSettings
            }
        },
        {
            name: 'fms',
            entry: fmsEntry,
            container: '#sub-page-main',
            activeRule: (location) => {
                return location.href.includes(defaultSettings.routeBaseFms);
            },
            props: {
                store,
                defaultSettings
            }
        },
        {
            name: 'ems',
            entry: emsEntry,
            container: '#sub-page-main',
            activeRule: (location) => {
                return location.href.includes(defaultSettings.routeBaseEms);
            },
            props: {
                store,
                defaultSettings
            }
        },
        {
            name: 'sys',
            entry: sysEntry,
            container: '#sub-page-main',
            activeRule: (location) => {
                return location.href.includes(defaultSettings.routeBaseSys);
            },
            props: {
                store,
                defaultSettings
            }
        },
        {
            name: 'plm',
            entry: plmEntry,
            container: '#sub-page-main',
            activeRule: (location) => {
                return location.href.includes(defaultSettings.routeBasePlm);
            },
            props: {
                store,
                defaultSettings
            }
        },
        {
            name: 'file',
            entry: fileEntry,
            container: '#sub-page-main',
            activeRule: (location) => {
                return location.href.includes(defaultSettings.routeBaseFile);
            },
            props: {
                store,
                defaultSettings
            }
        },
        {
            name: 'hrs',
            entry: hrsEntry,
            container: '#sub-page-main',
            activeRule: (location) => {
                return location.href.includes(defaultSettings.routeBaseHrs);
            },
            props: {
                store,
                defaultSettings
            }
        },
        {
            name: 'dcs',
            entry: dcsEntry,
            container: '#sub-page-main',
            activeRule: (location) => {
                return location.href.includes(defaultSettings.routeBaseDcs);
            },
            props: {
                store,
                defaultSettings
            }
        },
        {
            name: 'bms',
            entry: bmsEntry,
            container: '#sub-page-main',
            activeRule: (location) => {
                return location.href.includes(defaultSettings.routeBaseBms);
            },
            props: {
                store,
                defaultSettings
            }
        },
        {
            name: 'fas',
            entry: fasEntry,
            container: '#sub-page-main',
            activeRule: (location) => {
                return location.href.includes(defaultSettings.routeBaseFas);
            },
            props: {
                store,
                defaultSettings
            }
        },
        {
            name: 'wfe',
            entry: wfeEntry,
            container: '#sub-page-main',
            activeRule: (location) => {
                return location.href.includes(defaultSettings.routeBaseWfe);
            },
            props: {
                store,
                defaultSettings
            }
        },
        {
            name: 'saas',
            entry: `${saasEntry}?t=${Date.now()}`,
            container: '#sub-page-main',
            activeRule: (location) => {
                return location.href.includes(defaultSettings.routeBaseSaas);
            },
            props: {
                store,
                defaultSettings
            }
        }
    ];
}

export default qiankunStart;
