/*
 * @Author: web_liuqunyan
 * @Date: 2022-12-26 14:12:17
 * @LastEditors: lg
 * @LastEditTime: 2024-02-18 15:40:08
 * @FilePath: \gggj_main\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import HbUI from '@hb/hb-ui';

import { Message } from 'element-ui';

import qiankunStart from './qiankun.js';

import '@/styles/index.scss';

import './assets/icons'; // icon
import SvgIcon from '@/components/svg-icon/index.vue';

Vue.component('SvgIcon', SvgIcon);

const req = require.context('@/assets/icons/svg', false, /\.svg$/);
const requireAll = (requireContext) => requireContext.keys().map(requireContext);
requireAll(req);

Vue.config.productionTip = false;
Vue.use(HbUI);
//hb-service消息提示
window.messageCallback = (message, type = 'error') => {
    Message({
        message,
        type
    });
};

qiankunStart();

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app');
