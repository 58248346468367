/*
 * @Author: web_liuqunyan
 * @Date: 2022-12-26 14:12:17
 * @LastEditors: 陈迪秀 1170776994@qq.com
 * @LastEditTime: 2023-08-28 11:34:09
 * @FilePath: /gggj_main_dev/src/store/modules/user.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import router from '../../router/index.js';
import { logout } from '../../api/login.js';
const state = {
    token: '',
    userInfo: {},
    loginInfo: {
        account: '',
        password: ''
    }
};

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token;
    },
    SET_USERINFO: (state, data) => {
        state.userInfo = data;
    },
    SET_LOGININFO: (state, data) => {
        state.loginInfo = data;
    },
    SET_ENTERPRISENAME: (state, data) => {
        state.userInfo.enterpriseName = data;
    },
    SET_LOGINID: (state, data) => {
        state.userInfo.loginId = data;
    },
    SET_ENTERPRISEID: (state, data) => {
        state.userInfo.enterpriseId = data;
    }
};

const actions = {
    // 退出登录
    logout({ commit, state }) {
        logout({ loginId: state.userInfo.loginId, clientType: 0 }).finally(() => {
            commit('SET_TOKEN', ''); //清空token
            commit('SET_USERINFO', {}); //清空用户信息
            sessionStorage.clear(); //清除所有sessionStorage
            router.push('/');
            window.location.reload();
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
