<template>
    <el-scrollbar class="slider-auto">
        <el-menu v-if="currentMenuData" :collapse-transition="false" :default-active="defaultActive" :unique-opened="true" :collapse="isCollapse" router overflow-y: scroll>
            <template v-for="(group, index) in currentMenuData">
                <el-menu-item v-if="!group.children || group.children[0]?.type === 1" :key="index" :index="group.action">
                    <i :class="group.icon ? `iconfont ${group.icon}` : 'el-icon-menu'"></i>
                    <span slot="title" class="title">{{ group.name }}</span>
                </el-menu-item>
                <el-submenu v-else :key="`gggj_main_${index}`" :index="group.action">
                    <template slot="title">
                        <i :class="group.icon ? `iconfont ${group.icon}` : 'el-icon-menu'"></i>
                        <span slot="title" class="title">{{ group.name }}</span>
                    </template>
                    <template v-if="group.children">
                        <template v-for="item in group.children">
                            <el-menu-item v-if="item.type !== 3" :key="`${item.name}${defaultActive}`" :index="item.action">
                                <span slot="title" class="title">{{ item.name }}</span>
                            </el-menu-item>
                        </template>
                    </template>
                </el-submenu>
            </template>
        </el-menu>
    </el-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        isCollapse: {
            type: Boolean
        }
    },
    data() {
        return {
            // isCollapse: false,
            defaultActive: ''
        };
    },
    computed: {
        ...mapGetters(['menuList', 'menuLevel1Active']),
        currentMenuData() {
            return this.menuList[this.menuLevel1Active] && this.menuList[this.menuLevel1Active].children;
        }
    },
    watch: {
        $route: {
            handler(nv) {
                // console.log('路由变化', nv);
                this.defaultActive = nv.fullPath;
            }
        },
        deep: true,
        immediate: true
    },
    mounted() {
        // console.log(this.currentMenuData);
        this.defaultActive =
            this.$router.currentRoute.fullPath ||
            (this.menuList && this.menuList[this.menuLevel1Active] && this.menuList[this.menuLevel1Active].children[0] && this.menuList[this.menuLevel1Active].children[0].action);
    },
    methods: {
        // handleCollapse() {
        //     this.isCollapse = !this.isCollapse;
        //     this.$emit('changeAsideStyle', this.isCollapse);
        // }
    }
};
</script>

<style lang="scss" scoped>
.title {
    color: #b6bec9;
}
::v-deep .gggj_main-menu-item,
::v-deep .gggj_main-submenu__title {
    height: 44px !important;
    margin-bottom: 4px !important;
    line-height: 44px !important;
}

::v-deep .gggj_main-submenu__title:focus,
::v-deep .gggj_main-submenu__title:hover {
    background-color: transparent !important;
}
::v-deep .gggj_main-menu-item:focus {
    background-color: transparent !important;
}

::v-deep .gggj_main-menu-item:hover {
    color: #fff !important;
    background: rgba(29, 105, 254, 0.2);
    border-radius: 8px;
}

::v-deep .gggj_main-menu-item.is-active {
    background: linear-gradient(90deg, #53c6f8 0%, #165dff 100%);
    border-radius: 8px;
    .el-icon-menu,
    .title {
        color: #fff !important;
    }
}
::v-deep .gggj_main-submenu.is-active.is-opened .gggj_main-submenu__title {
    background: rgba(29, 105, 254, 0.2) !important;
    border-radius: 8px;
}
::v-deep .gggj_main-submenu.is-active .gggj_main-submenu__title {
    background: linear-gradient(90deg, #53c6f8 0%, #165dff 100%) !important;
    border-radius: 8px;
}

::v-deep .gggj_main-submenu .is-active {
    background: linear-gradient(90deg, #53c6f8 0%, #165dff 100%) !important;
    border-radius: 8px !important;
}

.gggj_main-menu--collapse {
    width: 44px !important;
    ::v-deep .gggj_main-submenu__title {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    ::v-deep .gggj_main-tooltip {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
}
.slider-auto {
    background: transparent;
    height: calc(100vh - 75px);
}
</style>
