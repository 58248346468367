import { axios } from '@hb/hb-services';

// 用户登录 - 账号密码登录
export const accountLogin = (data) => axios.post('/auth/v1/user/loginPassword', data);

// 用户登录 - 手机号验证码登录
export const codeLogin = (data) => axios.post('/auth/v1/user/smsCodelogin', data);

// 找回密码
export const retrievalPassword = (data) => axios.post('/auth/v1/user/retrievalPassword', data);

//获取企业账号信息
export const getEnterpriseAccountInfo = (data) => axios.get('/auth/v1/account/getEnterpriseAccountInfo', { params: data });

// 根据员工获取企业
export const queryEnterpriseDetail = (data) =>
    axios.get('/aas/v1/enterprise/queryEnterpriseDetail', {
        params: {
            staffId: data
        }
    });

//切换企业
export const chooseEnterprise = (data) => axios.post('/aas/v1/account/chooseEnterprise', data);

/**设置默认企业*/
export const setDefaultEnterprise = (data) => axios.get('/auth/v1/account/accountSetDefaultEnterprise', { params: data });

// 账号登出
export const logout = (data) => axios.get('/auth/v1/account/logout', { params: data });
