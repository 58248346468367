<template>
    <div class="tags-wrap">
        <div :class="['pointer', 'tag', firstIsActive ? 'tag-atv' : '']" @click="openFirstPage">{{ firstPageName }}</div>
        <div v-for="(item, index) in tabsView" :key="index" :class="['pointer', 'tag', item.path == routePath ? 'tag-atv' : '']" @click="openPage(item)">
            {{ item.meta.title }}
            <i class="el-icon-close icon" @click.stop="closePage(index)"></i>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters(['menuList', 'menuLevel1Active', 'visitedViews']),
        tabsView() {
            return this.visitedViews;
        },
        routePath() {
            return this.$route.path;
        },
        firstPageName() {
            const data = this.menuList[this.menuLevel1Active].children[0];
            const name = data?.children ? data.children[0]?.name : data?.name || '首页';
            return name;
        },
        firstIsActive() {
            let flag = false;
            const data = this.menuList[this.menuLevel1Active].children[0];
            // 判断第一个路由有没有子路由，如果有子路由就取子路由中的第一个路由，没有的话就取当前路由
            if (data?.children?.length ? data.children[0].action === this.routePath : data.action === this.routePath) {
                flag = true;
            }
            return flag;
        }
    },
    methods: {
        openFirstPage() {
            this.$router.push({
                path: this.menuList[this.menuLevel1Active].children[0].action
            });
        },
        openPage(item) {
            this.$router.push(item);
        },
        closePage(index) {
            this.$store.commit('app/DELETE_VISITEDVIEWS', index || 0);
            const currentIndex = this.tabsView.length - 1;
            if (currentIndex >= 0) {
                this.openPage(this.tabsView[currentIndex]);
            } else {
                this.openFirstPage();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@hb/gggj_lib/src/styles/element-variables.scss';
.tags-wrap {
    white-space: nowrap;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
    height: 40px;
    // background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
    &::-webkit-scrollbar {
        height: 5px !important;
    }
    .tag {
        // position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        color: #c9cdd4;
        font-size: 14px;
        height: 26px !important;
        line-height: 18px;
        font-weight: 400;
        background: #ffffff;
        // border: 1px solid $--color-primary-light-7;
        border-radius: 2px;
        padding: 2px 10px;
        box-sizing: border-box;
        margin-right: 10px;
        .icon {
            margin-left: 5px;

            // position: absolute;
            // top: -12px;
            // right: -12px;
            // padding: 7px 5px;
        }
    }
    .tag-atv {
        // color: #ffffff;
        color: $--color-primary;
        // background: $--color-primary-light-9;
        .icon {
            color: $--color-primary;
            z-index: 1;
        }
    }
}
</style>
