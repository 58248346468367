import { axios } from '@hb/hb-services';

// 发送短信验证码
export const sendSmsVerificationCode = (data) =>
    axios.get('/cus/v1/smsTools/sendSmsVerificationCode', {
        params: {
            mobile: data
        }
    });

// 获取子项目地址
export const getSysConfigValue = (data) => axios.get('/cus/v1/sysConfig/getSysConfigValue', data);

// tss(任务督办服务)首页看板接口-获取获取提示数量（目前督办在用）
export const getTips = () => axios.post('/tss/v1/bulletinBoard/getTips');
// tss(任务督办服务)消息提醒接口-更改显示状态为不显示
export const updateStatus = (data) =>
    axios.get('/tss/v1/alertMessage/updateStatus', {
        params: {
            id: data
        }
    });
// tss(任务督办服务)任务催办记录接口-获取任务催办记录详情数据
export const getDetail = (data) =>
    axios.get('/tss/v1/taskSuperviseRecord/getDetail', {
        params: {
            id: data
        }
    });
// tss(任务督办服务)任务接收记录接口-通过任务ID查询所有
export const taskFeedbackRecord = (data) =>
    axios.get('/tss/v1/taskReceive/getAllByTaskId', {
        params: {
            id: data
        }
    });
// tss(任务督办服务)消息提醒接口-消息盒子获取消息提醒详情数据
export const getBoardDetail = (data) =>
    axios.get('/tss/v1/alertMessage/getBoardDetail', {
        params: {
            id: data
        }
    });

/**
 * @description: 获取消息通知
 * @param {*} data
 * @return {*}
 */
export const getMessageApi = (data) => axios.post('/wfe/v1/getMessage', data);

/**
 * @description:查看消息并消耗
 * @param {*} data
 * @return {*}
 */
export const consumeMessageApi = (data) => axios.post('/wfe/v1/updateMessage', data);

// 生产管理-获取提示消息
export const pmsGetTips = () => axios.post('/pms/v1/common/msg/getTips');
