module.exports = {
    // eslint-disable-next-line lines-around-comment
    /**
     * @type {string}
     * @description 网站标题
     */
    title: '',

    /**
     * @type {string} zh中文 | en英文
     * @description 当前语言
     */
    lang: 'zh',

    /**
     * @type {number}
     * @description 请求超时时间，毫秒（默认2分钟）
     */
    timeout: 1200000,

    /**
     * @type {string}
     * @description 备案号
     */
    caseNumber: '备案号：鄂ICP备2020017363号-2',

    /**
     * @type {array}
     * @description 路由白名单
     */
    whiteList: ['/login', '/retrieval-password', '/404', '/transit-page'],

    /**
     * @type {string}
     * @description 自己的ip
     */
    myNextwork: '//127.0.0.1',

    /**
     * @type {string}
     * @description 生产管理模块路由前缀
     */
    routeBasePMS: '/layout/production-management',

    /**
     * @type {string}
     * @description gggjui模块路由前缀
     */
    routeBaseGggjUi: '/layout/gggj-ui',

    /**
     * @type {string}
     * @description 供应链模块路由前缀
     */
    routeBaseScm: '/layout/scm',

    /**
     * @type {string}
     * @description 合同模块路由前缀
     */
    routeBaseContract: '/layout/contract',

    /**
     * @type {string}
     * @description 督办模块路由前缀
     */
    routeBaseTss: '/layout/tss',

    /**
     * @type {string}
     * @description 督办模块路由前缀
     */
    routeBaseCrm: '/layout/crm',

    /**
     * @type {string}
     * @description 信息门户模块路由前缀
     */
    routeBaseWfs: '/layout/wfs',

    /**
     * @type {string}
     * @description 财务管理服务模块路由前缀
     */
    routeBaseFms: '/layout/fms',

    /**
     * @type {string}
     * @description Erp系统配置模块路由前缀
     */
    routeBaseSys: '/layout/sys',

    /**
     * @type {string}
     * @description Erp系统配置模块路由前缀
     */
    //     routeBasePlm: '/layout/plm',

    // }
    routeBasePlm: '/layout/plm',

    /**
     * @type {string}
     * @description 文档管理模块路由前缀
     */
    routeBaseFile: '/layout/file',

    /**
     * @type {string}
     * @description 人力资源模块路由前缀
     */
    routeBaseHrs: '/layout/hrs',

    /**
     * @description 数据爬虫模块路由前缀
     */
    routeBaseDcs: '/layout/dcs',

    /**
     * @description 建模平台
     */
    routeBaseBms: '/layout/bms',

    /**
     * @description 固定资产
     */
    routeBaseFas: '/layout/fas',

    /**
     * @description 工作流引擎
     */
    routeBaseWfe: '/layout/wfe',

    routeBaseSaas: '/layout/saas'
};
