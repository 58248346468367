/*
 * @Author: web_liuqunyan
 * @Date: 2022-12-26 14:12:17
 * @LastEditors: lg
 * @LastEditTime: 2024-04-18 09:34:44
 * @FilePath: \gggj_main\src\store\modules\power.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

// import { initM } from '../constants';

// const dealChildren = (item) => {
//     // const childItemAside = item.children?.every((item) => item.meta.isAside);
//     if (!item.children?.length) return false;
//     return item.children
//         ?.filter((item) => item?.meta?.isAside)
//         .map((childItem, index) => {
//             const childStr = childItem.path.charAt(0) === '/' ? `${childItem.path}` : `/${childItem.path}`;
//             return {
//                 ...childItem,
//                 action: `${item.action}${childStr}`,
//                 name: `${childItem.meta.title}-${index}`,
//                 powerId: `powerId-${childItem.path}-${index}`,
//                 pid: `pid-${childItem.path}-${index}`,
//                 children: childItem.children.length ? childItem.children.map((i, n) => dealChildren(i, n)) : null
//             };
//         });
// };

// const dealMenu = menuList.map((item) => {
//     const str = item.path.charAt(0) === '/' ? `${item.path}` : `/${item.path}`;
//     const copyItem = {
//         ...item,
//         // action: `/layout/saas${str}`
//         action: item.path
//     };
//     return {
//         ...item,
//         name: item.meta.title,
//         // action: `/layout/saas${str}`,
//         action: item.path,
//         children: dealChildren(copyItem)
//     };
// });

// const addRoutes = dealMenu.filter((item) => item?.meta?.isAside);

// console.log(addRoutes, '**********ddddd', initM);
const state = {
    menuList: [],
    menuLevel1Active: 1, //一级菜单当前选中项
    buttonPermission: {} //按钮权限 以路由地址为key， '路由地址': ['add', 'edit', 'delete']
};

const mutations = {
    SET_MENU_LIST: (state, arr) => {
        // 过滤掉没有路由的菜单数据
        const newArr = arr.filter((val) => val.action);
        const addArr = [
            // {
            //     powerId: '0001',
            //     pid: '0',
            //     name: 'saas派工',
            //     clientType: 0,
            //     type: 2,
            //     enterpriseType: ['4'],
            //     action: '/layout/saas',
            //     sort: 1,
            //     status: 1,
            //     icon: '',
            //     addTime: '2021-12-13 17:12:18',
            //     updTime: '2022-09-07 12:00:39',
            //     // children: [...addRoutes]
            //     children: [...initM]
            // }
        ];
        // console.log(newArr, 'newArr-----------', process.env.NODE_ENV);
        state.menuList = process.env.NODE_ENV === 'dev' ? [...newArr, ...addArr] : [...newArr];
        // state.menuList = [...newArr];

        // eslint-disable-next-line no-use-before-define
        state.buttonPermission = getNodeAction(newArr);
    },
    SET_MENU_LEVEL1_ACTIVE: (state, index) => {
        state.menuLevel1Active = index;
    }
};

const actions = {};

/* 处理按钮权限列表数据 */
const getNodeAction = (list, newNodeId = {}, pAction) => {
    for (const i in list) {
        if (list[i].type === 1 && pAction) {
            const action = list[i].action;
            const index = action.indexOf('_');
            const name = action.substring(index + 1, action.length);
            // eslint-disable-next-line no-extra-parens
            pAction in newNodeId ? newNodeId[pAction].push(name) : ((newNodeId[pAction] = []), newNodeId[pAction].push(name));
        }

        if (list[i].children) {
            getNodeAction(list[i].children, newNodeId, list[i].action);
        }
    }
    return newNodeId;
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
