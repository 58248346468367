<!--
 * @Author: web_liuqunyan
 * @Date: 2022-12-26 14:12:17
 * @LastEditors: qf
 * @LastEditTime: 2024-04-07 14:00:53
 * @FilePath: \gggj_main\src\components\Layout\components\AppHeader.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="header-wrap">
        <!-- {{ lastMessageArr.length }} -->
        <AppLogo class="header-logo" />
        <div class="header-items">
            <div v-for="(item, index) in menuList" :key="item.name" class="header-item" :class="menuLevel1Active == index ? 'header-item-active' : ''">
                <span @click="handleMenuLevel(index)">
                    <el-badge v-if="item.action.trim() === '/layout/wfe'" :value="messageTotal" class="badge-item">
                        <router-link :to="item.action">{{ item.name }}</router-link>
                    </el-badge>
                    <template v-else>
                        <router-link :to="item.action">{{ item.name }}</router-link>
                    </template>
                </span>
            </div>
        </div>
        <AppUserInfo />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppLogo from './AppLogo.vue';
import AppUserInfo from './AppUserInfo.vue';
import { getMessageApi } from '@/api/cus.js';
export default {
    components: {
        AppLogo,
        AppUserInfo
    },

    data() {
        return {
            messageTotal: 0,
            notifications: {},
            notifyPromise: Promise.resolve(),
            lastMessageArr: JSON.parse(sessionStorage.getItem('lastMessageArr')) || [],
            messageTimer: null
        };
    },
    computed: {
        ...mapGetters(['menuList', 'menuLevel1Active'])
    },

    watch: {
        $route: {
            handler(nv) {
                const getMenuList = JSON.parse(sessionStorage.getItem('vuex'))?.power?.menuList;
                const activeIndex = getMenuList && getMenuList.findIndex((item) => nv.path.includes(item.action) && item.action.includes('/'));
                this.$store.commit('power/SET_MENU_LEVEL1_ACTIVE', activeIndex > -1 ? activeIndex : 1);
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        //判断是否在iframe中,不在的话再去提示,在的话,就不提示,事务处理位置有嵌套整个项目的iframe
        if (window.parent === window) {
            this.getMessageList();
            this.messageTimer = setInterval(() => {
                this.getMessageList();
            }, 10000);

            // 清除定时器
            this.$on('hook:beforeDestroy', () => {
                clearInterval(this.messageTimer);
                this.messageTimer = null;
            });
        }
    },
    methods: {
        handleMenuLevel(index) {
            this.$emit('update-collapse');
            this.$store.commit('app/RESET_VISITEDVIEWS', []);
            this.$store.commit('power/SET_MENU_LEVEL1_ACTIVE', index);
            window.location.reload(); //要加载下,不然子项目样式会有问题
        },

        async handleSee({ data }) {
            try {
                console.log(data, 'ddd');
                // if (!data?.detailUrl) return false;
                this.$router.push({
                    // path: data?.detailUrl?.trim() || ''
                    path: '/layout/wfe/affairs/affairs-handle'
                });
                this.$router.go(0);
                // await consumeMessageApi({
                //     instanceId: data.instanceId,
                //     messType: data.messType,
                //     receiverId: data.receiverId,
                //     taskId: data.taskId
                // });
            } catch (error) {
                console.log(error);
            }
        },
        handleOpen(messageItem) {
            this.closeAll();
            const vm = this;
            const h = this.$createElement;
            // eslint-disable-next-line no-unused-vars
            const notify = this.$notify({
                title: '消息提醒',
                duration: messageItem?.duration || 0,
                offset: 10,
                position: 'bottom-right',
                dangerouslyUseHTMLString: true,
                message: h('div', { class: 'btnList' }, [
                    h('span', null, '你有'),
                    h('span', { style: 'color: red' }, `${this.messageTotal}`),
                    h('span', null, '条新消息!'),
                    h(
                        'span',
                        {
                            class: {
                                later: true
                                // disableBar: !messageItem?.data?.detailUrl
                            },

                            on: {
                                click: () => {
                                    vm.handleSee(messageItem);
                                }
                            }
                        },
                        ` 查看`
                    )
                ])
            });

            // //将通知实例放入
            this.notifications[messageItem.orderKey] = notify;
        },

        //用于后期可能关闭所有弹窗
        closeAll() {
            for (const key in this.notifications) {
                this.notifications[key].close();
                delete this.notifications[key];
            }
        },

        messageForEachNotice(notReadTotal) {
            if (notReadTotal.length > 0) {
                if (!notReadTotal.every((item) => item.hasNotice)) {
                    notReadTotal.forEach((element, index) => {
                        this.lastMessageArr[index].hasNotice = true;
                    });
                    this.handleOpen({
                        duration: 0
                    });
                }
                sessionStorage.setItem('lastMessageArr', JSON.stringify(this.lastMessageArr));
            }
        },
        //第一次进来提示所有消息,
        //然后

        /**
         * @description: 获取消息列表
         * @return {*}
         */
        async getMessageList() {
            try {
                const res = await getMessageApi();
                const notReadTotal = res.data.filter((item) => item.isRead !== 2); //1-未读,2-已读
                this.messageTotal = notReadTotal.length;
                notReadTotal.forEach((item) => {
                    if (this.lastMessageArr.findIndex((el) => el.id === item.id) <= -1) {
                        this.lastMessageArr.push({
                            hasNotice: false,
                            ...item
                        });
                    }
                });
                this.messageForEachNotice(this.lastMessageArr);
            } catch (error) {
                clearInterval(this.messageTimer);
                this.messageTimer = null;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.header-wrap {
    display: flex;
    align-items: center;
    .header-logo {
        margin-right: 48px;
    }
    .header-items::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    .header-items {
        min-width: 400px;
        overflow-x: auto;
        overflow-y: hidden;
        flex: 1;
        display: flex;
        .header-item {
            // width: 90px;
            min-width: 90px;
            font-size: 14px;
            text-align: center;
            line-height: 47px;
            height: 47px;
            max-height: 47px;
            a {
                color: #ffffff;
                font-weight: 600;
            }
        }
        .header-item-active {
            min-width: 106px;
            a {
                height: 30px;
                padding: 2px 8px;
                background: #ffffff;
                border-radius: 2px;
                color: #001529;
            }
        }
    }
}

::v-deep .el-badge__content.is-fixed {
    font-size: 14px;
    transform: scale(0.5);
    top: 3px;
    right: -12px;
}
.later {
    color: rgb(59, 59, 199);
    cursor: pointer;
}
.disableBar {
    color: #c0c4cc;
    cursor: not-allowed;
}
.badge-item {
    height: 18px;
    vertical-align: top;
}
</style>
<style>
.el-notification__title {
    font-size: 24px !important;
}
.btnList {
    font-size: 22px !important;
    line-height: 60px;
}
</style>
