<!-- eslint-disable no-unused-vars -->
<template>
    <div class="user-info-wrap">
        <div v-if="hasPower" class="message" @click="add">
            <el-badge v-if="num" :value="num" class="item"> </el-badge>
            <i class="el-icon-bell"></i>
            <div v-if="flag" class="tips">
                <ul>
                    <li v-for="(item, index) in tips" :key="index" @click="openurl(item)">
                        <el-tooltip effect="dark" :content="item.alertContent" placement="top">
                            <div class="content">{{ item.alertContent }}</div>
                        </el-tooltip>
                    </li>
                </ul>
            </div>
        </div>
        <div class="line"></div>
        <div class="enterpriseName_box">
            <template v-if="userInfo && userInfo.erpriseList && userInfo.erpriseList.length > 0">
                <el-dropdown>
                    <span class="el-dropdown-link">{{ userInfo.enterpriseName }}<i class="el-icon-arrow-down el-icon--right"></i></span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item, index) in userInfo.erpriseList" :key="index">
                            <div class="single_enterprise">
                                <p @click="handleSwitchEnterprise(item)">
                                    {{ item.enterpriseName }}
                                </p>
                                <!-- <el-button v-if="item.isDefaultEnterprise != 1" type="text" class="set_default" @click="handleSetDefaultEnterprise(item)">设置默认企业</el-button> -->
                                <el-button v-if="item.isDefault != 1" type="text" class="set_default" @click="handleSetDefaultEnterprise(item)">设置默认企业</el-button>
                                <el-button v-else type="text" :class="curcurEnterprise(item) ? '' : 'defaults'" icon="el-icon-success"></el-button>
                            </div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </template>
            <span v-else style="font-size: 14px">{{ userInfo.enterpriseName }}</span>
        </div>
        <div class="line"></div>
        <div class="user">
            <div class="user-img">
                <img v-if="userInfo.headImgUrl != '' && userInfo.headImgUrl != null" :src="userInfo.headImgUrl" />
                <img v-else src="@/assets/images/header-avatar.webp" />
            </div>
            <div class="user-info">
                <!--                <div class="role">超级管理员</div>-->
                <el-dropdown>
                    <span class="el-dropdown-link">{{ userInfo.name }}<i class="el-icon-arrow-down el-icon--right"></i></span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="handleLogout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <!-- 催办弹框 -->
        <el-dialog title="催办提醒" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <el-descriptions v-loading="loading" :column="1">
                <el-descriptions-item label="催办人">{{ urge.superviseName }}</el-descriptions-item>
                <el-descriptions-item label="催办任务">{{ urge.taskName }}</el-descriptions-item>
                <el-descriptions-item label="催办内容">{{ urge.content }}</el-descriptions-item>
            </el-descriptions>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 接收反馈弹框 -->
        <el-dialog title="接收反馈信息" :visible.sync="dialogVisible1" width="30%" :before-close="handleClose">
            <el-descriptions v-loading="loading1" :column="1">
                <el-descriptions-item v-if="urge1.receiveStatus !== ''" label="任务接收">{{ calcreceivestatus(urge1.receiveStatus) }}</el-descriptions-item>
                <el-descriptions-item v-if="urge1.feedbackInformation" label="反馈信息">{{ urge1.feedbackInformation }}</el-descriptions-item>
            </el-descriptions>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible1 = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible1 = false">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 建议点评弹框 -->
        <el-dialog title="建议点评" :visible.sync="dialogVisible2" width="40%" :before-close="handleClose">
            <div v-loading="loading2" class="baseinfo">
                <el-descriptions title="基本信息" :column="2">
                    <el-descriptions-item label="建议人">{{ advicebaseinfo.addName }}</el-descriptions-item>
                    <el-descriptions-item label="建议部门">{{ advicebaseinfo.addDep }}</el-descriptions-item>
                    <el-descriptions-item label="建议时间">{{ advicebaseinfo.addTime ? shortTime(advicebaseinfo.addTime) : '-' }}</el-descriptions-item>
                    <el-descriptions-item label="建议名称">
                        {{ advicebaseinfo.title }}
                    </el-descriptions-item>
                    <el-descriptions-item label="建议类型">{{ calcinfotype(advicebaseinfo.infoType) }}</el-descriptions-item>
                    <el-descriptions-item label="建议内容">{{ advicebaseinfo.content }}</el-descriptions-item>
                    <el-descriptions-item label="备注">{{ advicebaseinfo.remark }}</el-descriptions-item>
                </el-descriptions>
                <div class="baseinfo_file">
                    <div class="file_title">附件</div>
                    <div class="file_content">
                        <el-link v-if="advicebaseinfo.fileName" :href="advicebaseinfo.fileUrl" target="_blank" type="primary">{{ advicebaseinfo.fileName }}</el-link>
                    </div>
                </div>
                <el-descriptions title="建议点评" :column="2" style="margin-top: 20px">
                    <el-descriptions-item v-if="advicecontent.addName" label="点评人">{{ advicecontent.addName }}</el-descriptions-item>
                    <el-descriptions-item v-if="advicecontent.addTime" label="点评时间">{{ advicecontent.addTime ? shortTime(advicecontent.addTime) : '-' }}</el-descriptions-item>
                    <el-descriptions-item v-if="advicecontent.remark" label="点评意见">{{ advicecontent.remark }}</el-descriptions-item>
                </el-descriptions>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible2 = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getEnterpriseAccountInfo, setDefaultEnterprise } from '@/api/login';
import { shortTime } from '@hb/hb-utils';
import { getTips, updateStatus, getDetail, taskFeedbackRecord, getBoardDetail, pmsGetTips } from '@/api/cus.js';

export default {
    data() {
        return {
            enterpriseList: [],
            flag: false,
            tips: [],
            powerlist: [],
            num: 0,
            dialogVisible: false,
            loading: false,
            urge: {
                addTime: '',
                content: '',
                entepriseId: '',
                fileUrl: '',
                id: '',
                remark: '',
                superviseId: '',
                superviseName: '',
                taskId: '',
                taskName: ''
            },
            dialogVisible1: false,
            loading1: false,
            urge1: {
                content: '',
                currentProcess: '',
                enterpriseId: '',
                feedbackPhase: '',
                id: '',
                remark: '',
                taskId: ''
            },
            mainTaskId: '',
            loading2: false,
            dialogVisible2: false,
            eventId: '',
            advicebaseinfo: {},
            advicecontent: {},
            hasPower: false,
            checkObj: {
                id: '',
                alertStatus: '',
                emergencyLevel: ''
            },
            shortTime
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        }
    },

    /*    created() {
            queryEnterpriseDetail(this.userInfo.staffId).then(res => {
                this.enterpriseList = res.data
            })
        },*/
    mounted() {
        this.calcpowerlist();
        if (this.hasPower) {
            this.getmessage();
        }
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        curcurEnterprise(item) {
            return (item) => {
                const index = this.$store.state.user.userInfo.findIndex((items) => {
                    items.enterpriseId === item.enterpriseId;
                });
                return index === -1;
            };
        },
        async handleSwitchEnterprise(data) {
            //获取新的企业信息
            const res = await getEnterpriseAccountInfo({ enterpriseId: data.enterpriseId });
            if (res) {
                const data = res.data;
                this.$store.commit('user/SET_USERINFO', {
                    // 存储用户信息
                    name: data.loginName, //data.name
                    mobile: data.mobile,
                    headImgUrl: data.headImgUrl,
                    staffId: data.staffId,
                    loginId: data.loginId,
                    enterpriseId: data.enterpriseId,
                    enterpriseName: data.enterpriseName,
                    erpriseList: data.accountHaveEnterpriseList
                });
                if (data.powerList === null) data.powerList = [];
                this.$store.commit('power/SET_MENU_LIST', data.powerList); // 存储powerList
                // console.log('登录成功跳转的页面--------',data.powerList[0],data.powerList);
                const currentPath = data.powerList[0]?.action || '/layout/bms';
                this.$router.replace(`${currentPath}?v=${new Date().getTime()}`);
                setTimeout(() => {
                    this.$router.replace(currentPath);
                }, 0);
                // this.$router.push({
                //     path: data.powerList[0]?.action || '/layout/bms'
                // });
                // window.location.reload(true);
            }
        },

        /**设置默认企业*/
        async handleSetDefaultEnterprise(item) {
            const res = await setDefaultEnterprise({
                enterpriseId: item.enterpriseId
                // loginId: this.userInfo.loginId
            });
            if (res?.data) {
                this.$message.success('设置成功');
                //更新数据 修改isDefault值
                this.userInfo.erpriseList.forEach((val) => {
                    val.isDefault = val.enterpriseId === item.enterpriseId ? 1 : 0;
                });
            }
        },
        handleLogout() {
            this.$store.dispatch('user/logout');
        },
        //计算类型
        calcinfotype(num) {
            if (num === 0) {
                return '建议';
            }
            if (num === 1) {
                return '意见';
            }
        },
        //鼠标点击事件
        add() {
            this.flag = !this.flag;
        },
        //消息点击跳转
        async openurl(info) {
            //催办提醒
            if (info.mappingType === 3) {
                this.dialogVisible = true;
                this.loading = true;
                this.getreminders(info.mappingId);
                // this.$router.push({
                //   path: "/layout/tss/taskmanagement/my-tasks",
                //   query: {
                //     //info: row,
                //   },
                // });
            }
            //任务点评
            if (info.mappingType === 4) {
                await this.getmaintaskid(info.id);
                //console.log(info);
                this.$router.push({
                    path: '/layout/tss/taskmanagement/progress-details',
                    query: {
                        id: this.mainTaskId
                    }
                });
            }
            //建议点评
            if (info.mappingType === 5) {
                this.dialogVisible2 = true;
                this.loading2 = true;
                await this.getadvice(info.id);
            }
            //检查结果
            if (info.mappingType === 6) {
                await this.check(info.id);
                this.$router.push({
                    path: '/layout/tss/taskmanagement/my-tasksDetails',
                    query: this.checkObj
                });
            }
            if (info?.sysCode !== 'pms') {
                this.delmessage(info.id);
            }
        },
        //获取消息
        async getmessage() {
            const pmsMessage = await this.pmsGetTips();
            const tssMeassage = await this.tssGetTips();
            this.tips = [...pmsMessage, ...tssMeassage];
            this.num = this.tips.length;
        },
        async pmsGetTips() {
            const res = await pmsGetTips();
            if (res?.code === 0) return res.data;
            return [];
        },
        async tssGetTips() {
            const res = await getTips();
            if (res?.code === 0) return res.data;
            this.$message.error(res?.message);
            return [];
        },
        //点击一条消息后删除
        delmessage(id) {
            updateStatus(id).then((res) => {
                if (res.code === 0) {
                    this.getmessage();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        //打开弹框
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then((res) => {
                    console.log(res);
                    done();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        //获取催办提醒内容
        getreminders(id) {
            getDetail(id).then((res) => {
                if (res.code === 0) {
                    this.urge = { ...res.data };
                    this.loading = false;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        //获取接收反馈内容
        getreceiveback(id) {
            taskFeedbackRecord(id).then((res) => {
                if (res.code === 0) {
                    console.log(res);
                    if (res.data.length > 0) {
                        this.urge1 = { ...res.data[res.data.length - 1] };
                    }
                    //console.log(this.urge1);
                    this.loading1 = false;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        //通过任务id获取主任务id
        async getmaintaskid(id) {
            await getBoardDetail(id).then((res) => {
                if (res.code === 0) {
                    console.log(res);
                    this.mainTaskId = res.data.baseInfo.id;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        //建议点评内容
        async getadvice(id) {
            await getBoardDetail(id).then((res) => {
                if (res.code === 0) {
                    console.log(res);
                    this.advicebaseinfo = res.data.baseInfo;
                    this.advicecontent = res.data.mainInfo;
                    this.loading2 = false;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        //检查
        async check(id) {
            await getBoardDetail(id).then((res) => {
                if (res.code === 0) {
                    // console.log(res);
                    this.checkObj.id = res.data.baseInfo.id;
                    this.checkObj.alertStatus = res.data.baseInfo.alertStatus;
                    this.checkObj.emergencyLevel = res.data.baseInfo.emergencyLevel;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        //计算是否有企业督办
        calcpowerlist() {
            if (this.$store.state.power.menuList.length > 0) {
                this.$store.state.power.menuList.forEach((val) => {
                    if (val.name === '企业督办') {
                        return (this.hasPower = true);
                    }
                });
            }
        },
        //任务接收状态
        calcreceivestatus(type) {
            if (type === 1) {
                return '已接收';
            }
            if (type === 0) {
                return '未接收';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.single_enterprise {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        margin-right: 20px;
    }

    .set_default {
        color: #7a7878;

        &:hover {
            color: #100f0f;
        }
    }

    .defaults {
        color: #3bbf67 !important;
    }
}

.user-info-wrap {
    display: flex;
    align-items: center;
    margin-right: 20px;

    .message {
        cursor: pointer;
        position: relative;

        .el-icon-bell {
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
        }

        .tips {
            position: absolute;
            left: -150px;
            top: 33px;
            width: 300px;
            z-index: 999;
            background-color: #efffff;
            overflow-y: scroll;
            height: 150px;

            li {
                padding: 0 10px;
                line-height: 26px;

                .content {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .item {
            margin-top: -28px;
            margin-right: -32px;
        }
    }

    .line {
        display: inline-block;
        height: 22px;
        width: 1px;
        background: rgba(255, 255, 255, 0.5);
        margin: 0 16px;
    }

    .enterpriseName_box {
        cursor: pointer;

        span {
            color: #fff;
        }
    }

    .user {
        display: flex;

        .user-img {
            height: 32px;
            width: 32px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 16px;
            background: rgb(217, 217, 217);

            img {
                height: 100%;
                width: 100%;
            }
        }

        .user-info {
            display: flex;
            align-items: center;
            cursor: pointer;

            .role {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.7);
                padding-bottom: 2px;
            }

            ::v-deep .gggj_main-dropdown .el-dropdown-link {
                cursor: pointer;
                font-size: 14px;
                color: #fff;
                font-weight: 500;
            }

            ::v-deep .el-icon-arrow-down {
                font-size: 12px;
                font-weight: bold;
            }
        }
    }

    .baseinfo_file {
        margin: 0 16px;
        height: 156px;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #ebeef5;

        .file_title {
            height: 40px;
            line-height: 40px;
            background-color: #f9f9f9;
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #606266;
            padding: 0 16px;
        }

        .file_content {
            padding: 16px 16px;
        }
    }
}
</style>
