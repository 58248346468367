<!--
 * @Author: lg
 * @Date: 2022-05-10 19:27:26
 * @LastEditors: lg
 * @LastEditTime: 2024-02-23 13:26:15
 * @Description:
 * @FilePath: \gggj_main\src\components\Layout\index.vue
-->
<template>
    <el-container>
        <el-header v-show="isShowMain"><AppHeader @update-collapse="handleUpdateCollapse" /></el-header>
        <div class="container" :class="{ 'is-show-affair': !isShowMain }">
            <div v-show="isShowMain && !getUrl().includes('layout/saas')" class="close__aside--icon" :class="[{ direction: isCollapse }, 'close__aside--icon']" @click="handleSlideCollapse">
                <svg-icon :icon-class="isCollapse ? 'expand' : 'retract'" />
            </div>
            <el-aside v-show="isShowMain && !getUrl().includes('layout/saas')" :style="asideStyle" class="el-aside-area">
                <div class="gap-area"></div>
                <AppSidebar ref="sidebar" :is-collapse="isCollapse" />
            </el-aside>
            <div class="sub-page-wrap">
                <!-- <i :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'" class="aside-icon" :style="{ width: isCollapse ? '34px' : '', color: 'red' }" @click="handleSlideCollapse"></i> -->
                <AppTagsView v-show="isShowMain && !getUrl().includes('layout/saas')" class="breadcrumb" @toggleClick="handleCollapse" />
                <div class="sub-page-main" :style="{ padding: !getUrl().includes('layout/saas') ? '10px' : 0 }">
                    <div id="sub-page-main"></div>
                    <router-view />
                </div>
            </div>
        </div>
    </el-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { AppHeader, AppSidebar, AppTagsView } from './components';

export default {
    name: 'Layout',
    components: {
        AppHeader,
        AppSidebar,
        AppTagsView
    },
    data() {
        return {
            isCollapse: false,
            token2: '',
            asideStyle: {
                width: '232px'
            },
            isShowMain: true //是否显示菜单和头部内容
        };
    },
    computed: {
        ...mapGetters(['token', 'theme'])
    },
    created() {
        if (this.$route.query?.isAffairDetailPage) {
            this.isShowMain = !this.$route.query.isAffairDetailPage;
        }
        this.isCollapse = this.getUserConfig()?.collapse;
        this.asideStyle = {
            width: this.isCollapse ? '' : '232px'
        };
    },
    methods: {
        getUserConfig() {
            return JSON.parse(localStorage.getItem('user'));
        },
        getUrl() {
            return window.location.href;
        },
        handleUpdateCollapse() {
            this.isCollapse = this.getUserConfig()?.collapse;
            this.asideStyle = {
                width: this.isCollapse ? '' : '232px'
            };
            // console.log('-----------', this.isCollapse);
        },

        handleSlideCollapse() {
            this.isCollapse = !this.isCollapse;
            this.asideStyle = {
                width: this.isCollapse ? '' : '232px'
            };

            const user = Object.assign({}, this.getUserConfig(), { collapse: this.isCollapse });
            console.log(user, 'user-------------');
            localStorage.setItem('user', JSON.stringify(user));
        },
        handleCollapse() {
            this.$refs.sidebar.handleCollapse();
        },
        handleToken() {
            this.$store.commit('user/SET_TOKEN', this.token2);
        },
        getToken() {
            console.log('token');
        }
    }
};
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}
.el-header {
    height: 48px !important;
    background: #0d284d;
    .header-wrap {
        height: 100%;
    }
}
.container {
    display: flex;
    height: calc(100vh - 48px);
    position: relative;
    .el-aside {
        height: calc(100vh - 48px);
        overflow: hidden;
        .menu-wrap {
            // width: 100%;
            // height: 100%;
            height: calc(100vh-60px);
            background: #ffffff;
            ::v-deep .el-menu {
                height: 100%;
            }
        }
    }
    .el-aide-area {
        overflow-y: auto;
    }
    .sub-page-wrap {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        width: calc(100vw - 200px);
        .sub-page-main {
            flex: 1;
            flex-direction: column;
            // padding: 10px;
            background: #ffffff;
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            overflow-y: auto;
            #sub-page-main {
                height: 100%;
                width: 100%;
            }
        }
    }
    &.is-show-affair {
        height: 100vh;
        .sub-page-main {
            padding: 0;
        }
    }
}

::-webkit-scrollbar-track-piece {
    // background-color: rgba(0, 0, 0, 0);
}

::v-deep .gggj_main-aside {
    background: #0d284d !important;
    padding: 0px 8px 0;
    position: relative;
    .gap-area {
        height: 33px;
    }
    .gggj_main-menu {
        background: transparent !important;
        border: none;
    }
}
.sub-page-wrap {
    position: relative;
}
.close__aside--icon {
    position: absolute;
    left: 216px;
    top: 3px;
    z-index: 5;
    width: 16px;
    height: 24px;
    background: #fff;
    // background: red;
    border-radius: 2px 0px 0px 2px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.direction {
    left: 60px;
    border: 1px solid #dfe1e6;
    border-radius: 0 2px 2px 0;
}
</style>
